import React from "react";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Container, ResponsiveEmbed, Tabs, Tab  } from 'react-bootstrap'

import './App.css';
import './index.css';
import './tutorials.css';

function Tutorials() {
    return (
      <div>
        <Container className="tutorial-container">
            <h3 className='tutorial-header'>DQO Tutorials</h3>
            <p className="tutorial-text">
                Welcome to the DQO tutorial page. These tutorials are designed to help you get better acquainted with the ARM data quality process, as well as the various resources and tools we have made available for data monitoring. This page is a bit sparse right now, but we will be continually adding new content. If you have anything specific you'd like to see, please email us at <a href = "mailto:dqmgr@arm.gov">dqmgr@arm.gov</a>. <br/>  <br/> 
                If you're new, we reccomend starting with <HashLink to='/tutorials/#dqo-process-overview'>this video</HashLink> which gives an overview of the process by which our office monitors incoming data and communicates findings with the rest of the ARM program. 
            </p>


            <div className = "toc-container">
                <h3>Contents:</h3>
                <ul className="toc-list">
                    <li> <HashLink to="#general">General</HashLink>
                      <ul>
                        <li> <HashLink to="#dqo-process-overview">Data Monitoring and Quality Reporting Process Overview</HashLink></li>
                      </ul>
                    </li>
                    
                    <li> <HashLink to="#web-tools">Web Tools</HashLink>
                      <ul>
                        <li> <HashLink to="#dq-plotbrowser-tutorial">DQ-Plotbrowser Overview</HashLink></li>
                        <li> <HashLink to="#dq-zoom-tutorial">DQ-Zoom Overview</HashLink></li>
                      </ul>
                    </li>

                    <li> <HashLink to="#dq-forms">Data Quality Forms</HashLink>
                      <ul>
                        <li> <HashLink to="#dqr-reprocessing">Reprocessing DQR Example</HashLink></li>
                      </ul>
                    </li>
                    
                </ul>
            </div>
            <br/>
            <p style={{fontSize:20}}>Looking for a quick answer? Check out our <Link to="/FAQ">FAQ page</Link>.</p>
            <br/>


            <section className='tutorial-section' id="general">
                <h3 className='tutorial-header'>General</h3>
                <div className='tutorial-div' id="dqo-process-overview">
                    <h3 className='tutorial-subheader'>Data Monitoring and Quality Reporting Process Overview</h3>
                    <ResponsiveEmbed className="tutorial-video-container" aspectRatio="16by9">
                        <iframe src="https://www.youtube.com/embed/Xn3ZtHFBI-U" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </ResponsiveEmbed>
                </div>
            </section>
            <hr/>
            <section className='tutorial-section' id="web-tools">
                <h3 className='tutorial-header'>Web Tools</h3>
                <div className='tutorial-div' id="dq-plotbrowser-tutorial">
                    <h3 className='tutorial-subheader'>DQ-Plotbrowser Tutorial</h3>
                    <ResponsiveEmbed className="tutorial-video-container" aspectRatio="16by9">
                        <iframe src="https://www.youtube.com/embed/D1BrW0NJJD8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </ResponsiveEmbed>
                </div>
                <br/>
                <div className='tutorial-div' id="dq-zoom-tutorial">
                    <h3 className='tutorial-subheader'>DQ-Zoom Tutorial</h3>
                    <ResponsiveEmbed className="tutorial-video-container" aspectRatio="16by9">
                        <iframe src="https://www.youtube.com/embed/1vLIzoTOvvo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </ResponsiveEmbed>
                </div>
            </section>
            <hr/>
             <section className='tutorial-section' id="dq-forms">
                <h3 className='tutorial-header'>Data Quality Forms</h3>
                <div className='tutorial-div' id="dqr-reprocessing">
                    <h3 className='tutorial-subheader'>Reprocessing DQR Example</h3>
                    <ResponsiveEmbed className="tutorial-video-container" aspectRatio="16by9">
                        <iframe src="https://www.youtube.com/embed/_aeyNbUqSng" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </ResponsiveEmbed>
                </div>
            </section>

            {
            // <section className='tutorial-section' id="web-tools">
            //     <h3 className='tutorial-header'>Web Tools</h3>
            //     <section className='tutorial-section' id="dq-plotbrowser">
            //         <h3 className='tutorial-header-small'>DQ-Plotbrowser</h3>
            //         <div className='tutorial-div' id="dq-plotbrowser-tutorial">
            //             <h3 className='tutorial-subheader'>DQ-Plotbrowser Tutorial</h3>
            //             <ResponsiveEmbed className="tutorial-video-container" aspectRatio="16by9">
            //                 <iframe src="https://www.youtube.com/embed/D1BrW0NJJD8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            //             </ResponsiveEmbed>
            //         </div>
            //     </section>
            // </section>
            }
        </Container>
      </div>
    );

}

export default Tutorials; 