import React, { Component } from 'react';
import { Accordion, Card, Button, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';

import './App.css';
import './index.css';
import './FAQ.css';

export default class FAQ extends Component {
    constructor(props) {
        super(props)

       
    }

    render() {
        var generalFaqData = [
            {
                'Q': 'Have a Data Quality question and don\'t know where to start?',
                'A': 'The Data Quality Office knows a lot about ARM data. If we don\'t know the answer to your question we will find the correct'+
                     ' person to answer your question. Just shoot us an email at <a href=mailto:dqmgr@arm.gov>dqmgr@arm.gov</a>.'
            },
            {
                'Q': 'Who should I contact if I have a question about DQO web tools?',
                'A': 'You can open a ticket on ServiceNow by sending an email to <a href=mailto:dqtools@arm.gov>dqtools@arm.gov</a>. Alternatively,'+
                     ' you can email the DQO (<a href=mailto:dqmgr@arm.gov>dqmgr@arm.gov</a>) directly.'
            },
            {
                'Q': 'Who should I contact if I have a question about data quality forms (DQAs, DQPRs, DQRs) or other science questions?',
                'A': 'Alyssa Sockol (<a href=mailto:asockol@ou.edu>asockol@ou.edu</a>) or Ken Kehoe (<a href=mailto:kkehoe@ou.edu>kkehoe@ou.edu</a>).'
            },
            {
                'Q': 'Who should I contact if I have a question about DQO static plots, metrics, or QC tests?',
                'A': "Corey Godine (<a href=mailto:cgodine@ou.edu>cgodine@ou.edu</a>) or Ken Kehoe (<a href=mailto:kkehoe@ou.edu>kkehoe@ou.edu</a>)."
            },
            {
                'Q': 'I do not see any DQO plots or metrics for an instrument even though it should be collecting data. What should I do?',
                'A': 'There are a number of potential reasons that DQO plots would not exist. The most likely scenarios are:\n\n' +
                     '<ul><li>An issue with collection (i.e. data exist on the instrument or site computer but are not transferred to ADC)</li>'+
                     '<li>An issue with the ingest process (i.e. data is being collected but no netCDFs are being created. The DQO processes netCDF data)</li>' +
                     '<li>An issue with DQO processing (i.e. our code encountered an error when generating plots & metrics)</li></ul>' +
                     ' If you have access to either the site or DMF data directories check there first for the data files. If no NetCDFs exist,'+
                     ' you can safely assume something went wrong in the ingest process and should submit a ServiceNow ticket.'
            }
        ]

        var formFaqData = [
            {
                'Q': 'What do the acronyms DQPR, DQR, and DQA stand for?',
                'A': 'DQPR = Data Quality Problem Report, DQR = Data Quality Report, DQA = Data Quality Assessment.'
            },
            {
                'Q': 'What’s the difference between DQPRs and DQRs?',
                'A': 'DQPRs are internal online forms meant to help diagnose and resolve potential instrument issues between ARM personnel.' +
                    ' They are not visible to end users and do not have to be as formal as DQRs. DQRs, on the other hand, are written once an' +
                    ' instrument problem is identified, and are visible to end users. They serve as formal alerts for time periods during which' +
                    ' an instrument operated outside of normal conditions.'
            },
            {
                'Q': 'What is the difference between the “Missing Data” and “No Data” quality code on the DQPR submittal forms? How do these' +
                     ' differ from the “Missing” quality category on DQR submittal forms?',
                'A': 'The “Missing Data” quality code on the DQPR forms are for data/data variables that are flagged as missing (generally with' +
                     ' a -9999 value within the netCDF files). The “No Data” quality code is for data that simply does not exist. The netCDF files' +
                     ' for these data would be partially or completely missing in these cases. Having a distinction helps to diagnose the problem.\n\n' +
                     ' The “Missing” quality category on the DQR forms, however, covers both data flagged as missing and data that does not exist.' +
                     ' It is not necessary to distinguish between the two when selecting the quality category on the DQR forms (though, it might'+
                     ' still be helpful to further describe the issue in question in the DQR Description Box).' 
            },
            {
                'Q': 'Are DQPRs required for every DQR I submit? Can I submit a DQR without submitting an accompanying DQPR?',
                'A': 'No, DQPRs are not required for a DQR. If an instrument issue is identified and resolved quickly, for example, the mentor'+
                     ' can submit a DQR for the issue without needing to submit an accompanying DQPR. All instrument issues require a DQR, but' +
                     ' not all DQRs require an accompanying DQPR.'
            },
            {
                'Q': 'How do I track the progress of my Reprocessing DQR?',
                'A': 'Once a reprocessing DQR is submitted and approved, the progress of the reprocessing can be tracked on <a href=https://i.arm.gov>i.arm.gov</a> by clicking' +
                     ' the “REPROC” tile. There, you can search for your DQR by site, facility, instrument, date, etc. Sometimes it takes some time' +
                     ' for your reprocessing task to show up on this website, so don’t be alarmed if it doesn’t show up right away.'
            },
            {
                'Q': 'What variables should I select when filling out my DQR?',
                'A': 'The variables selected in a DQR should accurately reflect what data is affected. If the DQR describes missing data, all data' +
                     ' variables should be selected. If the DQR describes a problem with the instrument where some variables are incorrect but others' +
                     ' are correct, only select the incorrect variables. Typically the location and quality control variables are not selected for a' +
                     ' DQR describing a data quality issue.'
            },

        ]

        var webToolFaqData = [
            {
                'Q': 'What are the differences between the various web tools for data analysis provided by the DQO? (DQ-Explorer, DQ-Plotbrowser, and DQ-Zoom)',
                'A': 'DQ-Plotbrowser and DQ-Explorer provide slightly differing methods of viewing static plots and metric summaries produced by the DQO for'+
                     ' ARM instrumentation. DQ-Plotbrowser is designed for quickly viewing many static plots at once, while DQ-Explorer is designed for more in-depth'+
                     ' analysis and also includes metrics tables showing times where QC flags are tripped for the data. DQ-Zoom differs in that it plots data'+
                     ' interactively on the fly and allows for much more flexibility in the way data is viewed.'
            },
            {
                'Q': 'Plots look different now than in the past. What happened?',
                'A': 'The DQO has recently undergone a transition in the software used for generating static plots, and all of our plots are now processed using'+
                     ' various Python libraries. If there are any questions about a specific plot, do not hesitate to reach out to Corey Godine (<a href=mailto:cgodine@ou.edu>cgodine@ou.edu</a>)'+
                     ' or Ken Kehoe (<a href=mailto:kkehoe@ou.edu>kkehoe@ou.edu</a>).'
            },
            {
                'Q': 'How often are static plots updated?',
                'A': 'Static plots are updated whenever there is a new or updated ingested netCDF file added to the DMF data directory for that particular instrument.'+
                     ' The processing software will search all of the data and process new files as they come in.'
            },
        ]

        var dqExplorerFaqData = [
            {
                'Q': 'What do the colored tiles on the DQ-Explorer metrics display represent?',
                'A': 'DQ-Explorer has several different colored tiles that represent varying statuses of data. Green tiles represent data with no QC issues,'+
                     ' yellow represents an Indeterminate issue that warrants further exploration, and red represents data that is failing one or more QC tests.'+
                     ' Tiles with multiple issues are displayed as blue. Additionally, grey and orange tiles represent where data is either missing or not available.'+
                     ' Clicking on tiles or the variable names on the left side of the metrics table will show more information about that particular bin of data.'
            },
            {
                'Q': 'What is the difference between “Missing” and “Not Available” data?',
                'A': 'When data is "Missing", there is a corresponding data file present that has been processed by the DQO, but there is no data present'+
                     ' in the file for the variable(s). In the netCDF file, missing data is generally shown with (-9999). When data is shown as "Not Available",'+
                     ' the corresponding data has not been processed by the DQO. If data is expected or a file for that day exists, a DQPR may need to be submitted.'
            }
        ]

        var dqZoomFaqData = [
            {
                'Q': 'How do I plot multiple variables in DQ-Zoom?',
                'A': 'To start, select the datastream you are interested in from the datastream dropdown or construct it by selecting'+
                     ' site/class/facility/level then select a date range (you will not be able to change this without clearing your plot so be' +
                     ' sure to select the right range!) and variable and click “Generate Plot”. After the plot loads, a new “Add to Plot” button' +
                     ' will appear. Go through the same process as above, but this time click “Add to Plot”. The application will automatically match' +
                     ' the variable units and either put it on the same axis as your first variable (if the units are the same) or on a secondary y ' +
                     ' axis (if the units differ). You can remove a line from the plot by double clicking its entry in the legend.'
            },
            {
                'Q': 'How do I save plot views in DQ-Zoom?',
                'A': 'You can add a plot view to your favorites by clicking the heart icon at the bottom of the sidebar menu while a plot is active on-screen' +
                     ' (NOTE: the favorites icon will only appear if you are logged in with ARM SSO). This will open up a menu with options to save the favorite with either' +
                     ' dynamic or static date ranges. Choosing "Dynamic Date Range" will give you the option to setup the plot such that it pulls in the latest x number of days of data.' +
                     ' To see all your saved favorites, click the "View Favorites" button at the top of the sidebar menu.'
            }
        ]



        return (
          <div>
            <Container className="faq-container">
                <h3 className='faq-header'>Frequently Asked Questions:</h3>
                <br/>
                <h3 className='faq-subheader'>General</h3>
                <FAQAccordian faqData={generalFaqData}/>
                <hr/>
                <h3 className='faq-subheader'>Data Quality Forms</h3>
                <FAQAccordian faqData={formFaqData}/>
                <hr/>
                <h3 className='faq-subheader'>Web Tools</h3>
                <FAQAccordian faqData={webToolFaqData}/>
                <h3 className='faq-smallsubheader'>DQ-Explorer</h3>
                <FAQAccordian faqData={dqExplorerFaqData}/>
                <h3 className='faq-smallsubheader'>DQ-Zoom</h3>
                <FAQAccordian faqData={dqZoomFaqData}/>
                <hr/>
                <br/>
                <p>Looking for more? Check out our <Link to="/tutorials">tutorials page</Link>.</p>
            </Container>
          </div>
        )
    }
    
}

class FAQAccordian extends Component {
    render() {
        console.log(this.props.faqData)
        return(
            <Accordion>
                {this.props.faqData.map((curr_faq, i) => (
                    <Card key={"card"+i}>

                        <Accordion.Toggle as={Card.Header} variant="link" eventKey={i+1}>
                          {curr_faq.Q}
                        </Accordion.Toggle>


                      <Accordion.Collapse  key={"cardcollapse"+i} eventKey={i+1}>
                        <Card.Body dangerouslySetInnerHTML={{__html: curr_faq.A }}></Card.Body>
                      </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        )
    }
}