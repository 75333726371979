import React, { Component } from 'react';
import { Container,Row,Col } from 'react-bootstrap'

import './App.css';
import './index.css';
import './About.css';

import DQO_flowchart from "./images/DQO_flowchart.png"


export default class About extends Component {
  render() {
    return (
    	<div className="about-container">
	        <Container style={{paddingBottom: 75}}>
	          <Row>
	          	<h3 className="about-header">About the DQO:</h3>
	          	<h3 className='about-subheader'>Overview:</h3>
	          	<Col> 
		            <p className="about-text">
		            	The ARM Data Quality Office (DQO) is located at the University of Oklahoma within the 
		            	Cooperative Institute for Severe and High-Impact Weather Research and Operations (CIWRO). 
		            	The DQO serves as the first line of defense for discovering ARM data quality issues via manual 
		            	& automated data quality reporting alongside web-based tools for data inspection
		            </p>

		            <p className="about-text">
		            	The DQO is recognized as an independent arbiter of data quality for the ARM program, and utilizes 
		            	unique tools, capabilities, and processes to minimize the time required to address instrument problems. 
		            	This, combined with collaboration with ARM’s instrument mentors, site operators and data system staff 
		            	allows ARM to achieve its mission of providing the best-possible measurements for scientific research.
	            	</p>
	            </Col>

	            <h3 className='about-subheader'>QC Process:</h3>
	            <Col lg={6} md={12}>
		            <p className="about-text">
		            	The DQO reviews most datastreams produced by the ARM Data Center (ADC) for potential data issues and instrument failures. 
		            	The reviews center around metric summaries of data coupled with plots and movies. The inspection of metrics and plots 
		            	begin with employed student analysts who review data weekly for fixed sites and up to daily for mobile facilities. 
		            	Student analysts submit weekly Data Quality Assessment (DQA) reports to communicate overall data quality observations 
		            	to the ARM program. Student analysts learn to identify problems using an internal training program utilizing over 20 
		            	years of DQO experience of ARM instrument behavior, and a suite of web-based tools to visualize instrument data and 
		            	quality control (QC) information.
		            </p>

		            <p className="about-text">
		            	If a potential data quality issue is found during the student analyst’s data review, a Data Quality Problem Report (DQPR) 
		            	is submitted to alert appropriate ARM personnel of potentially unacceptable data via a common reporting system. 
		            	DQPRs enable interactive discussions and suggested action from all involved in the quality assurance process, and make it 
		            	possible to track a problem from detection through resolution to documentation.
		            </p>

		            <p className="about-text">
		            	The final step in the process results in a Data Quality Report (DQR). DQRs are written statements of data quality intended 
		            	for use by data users and document individual datastream(s) over specified time range(s), accompanied by a quality category 
		            	and a description of the issue, which may also describe how to fix the issue. DQRs are displayed to the data user via 
		            	ARM Data Discovery during the data ordering process, and are included with their data order. DQRs are the primary method of 
		            	communicating to users all data quality issues not described in instrument handbooks or flagged by embedded QC. 
		            	The data user may elect to have data listed in DQRs removed before delivering data.
		            </p>
		          </Col>
		          <Col style={{display:'flex', justifyContent:  'center'}} lg={6} md={12}>
	            	<img style={{maxHeight:700}} src={DQO_flowchart}/>
	            </Col>

            	<h3 className='about-subheader'>Tools & Capabilities:</h3>
            	<Col> 
	            		<p className="about-text">The DQO maintains the following web-based tools to assist in the review process and for data inspection by ARM personnel:
	            			<br/>
	            			<br/>
		            		<ul>
		            			<li><b>DQ Explorer:</b> A data summary visualization tool engineered to rapidly review ancillary quality control variables indicating a failure</li>
		            			<li><b>DQ Plotbrowser:</b> A tool designed to quickly & efficiently view plots and movies produced from ARM data in near real-time, with more than 5000+ generated daily.</li>
		            			<li><b>DQ Zoom:</b> An interactive plotting tool designed to dynamically plot ARM data for both 1D and 2D data.</li>
		            		</ul>
	            		</p>
		            <p className="about-text">
		            	The DQO is adept at mobile facility deployments and time sensitive instrument deployments. 
		            	Most analysis is performed via data present on the ADC filesystem, but when mobile facilities are deployed in remote locations where data 
		            	transfer is limited, the Site Data Systems is able to process data on site and deploy the DQO software suite 
		            	to produce metrics and plot summaries of the data. These summaries are significantly smaller than the data 
		            	files and can be transferred over limited bandwidth networks to the ADC for inspection by DQO analysts, 
		            	instrument mentors and principle investigators. When instrument mentors and principle investigators are deployed 
		            	at the remote site, the locally viewable DQO plots are the fastest and easiest method for direct data review 
		            	revealing issues for on-site personnel, expediting problem resolution.
		            </p>
		            <p className="about-text">
		            	The DQO also works closely with instrument mentors to produce specific software for alerting and visualizations. 
		            	Examples include alert emails when a failure is detected sent to DQO staff and instrument mentors for further inspection, 
		            	and derived winds aloft from the doppler lidar system for tethered balloon operational decisions. 
		            	The skill set of the DQO allows for software development spanning a one-off request to simplify an instrument mentor's 
		            	daily task to significant contributions to open-source software repositories, to high performance computing design for balancing system load of operational systems.
		            </p>
		           </Col>
	          </Row>
	        </Container>
        </div>
    )
  }
}