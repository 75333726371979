import React from 'react';
import ReactDOM from 'react-dom';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import App from './App';
import {HomePage, NavBar} from './App'
import Tutorials from './Tutorials';
import FAQ from './FAQ';
import About from './About';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Router>
      <NavBar/>
       <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/tutorials" component={Tutorials} />
        <Route path="/FAQ" component={FAQ} />
        <Route path="/About" component={About} />
      </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
